<template>
  <div>
    <form-wizard
      color="#00cfe8"
      :title="null"
      :subtitle="null"
      shape="circle"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="createCourseValidation"
    >
      <tab-content title="Course Details">
        <validation-observer ref="courseInfoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Course Details</h5>
              <small class="text-muted"> Enter the Course Details. </small>
            </b-col>
            <b-col md="6">
              <b-form-group label="title" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="title"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="title"
                    v-model="course.title"
                    :state="errors.length > 0 ? false : null"
                    placeholder="couse name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="course duration in monthes  "
                label-for="duration"
              >
                <validation-provider
                  #default="{ errors }"
                  name="duration"
                  rules="required|numeric"
                >
                  <b-form-input
                    id="duration"
                    v-model="course.duration"
                    :state="errors.length > 0 ? false : null"
                    placeholder="course duration in monthes"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="price " label-for="price">
                <validation-provider
                  #default="{ errors }"
                  name="price"
                  rules="required|numeric"
                >
                  <b-form-input
                    id="price"
                    v-model="course.price"
                    :state="errors.length > 0 ? false : null"
                    placeholder="price"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="priceAfterDiscount"
                label-for="priceAfterDiscount"
              >
                <validation-provider
                  #default="{ errors }"
                  name="priceAfterDiscount"
                  :rules="`numeric|max_value:${course.price}`"
                >
                  <b-form-input
                    id="priceAfterDiscount"
                    v-model="course.priceAfterDiscount"
                    :state="errors.length > 0 ? false : null"
                    placeholder="price After Discount"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="description" label-for="description">
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required|min:20"
                >
                  <b-form-textarea
                    id="description"
                    placeholder="description"
                    v-model="course.description"
                    :state="errors.length > 0 ? false : null"
                    rows="8"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Course Cover Image"
                label-for="CorseCoverImage"
              >
                <validation-provider
                  #default="{ errors }"
                  name="CorseCoverImage"
                  rules="required"
                >
                  <b-form-file
                    :state="Boolean(course.imageCover)"
                    id="CorseCoverImage"
                    accept="image/*"
                    v-model="course.imageCover"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-row>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="category"
                    rules="required"
                  >
                    <b-form-group
                      label="select Course Category"
                      label-for="category"
                    >
                      <v-select
                        v-model="course.category"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        id="category"
                        :options="categories"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { required, email, max_value, numeric } from "@validations";
// import { codeIcon } from "./code";
import categoriesApi from "@/apiServices/categoriesApi";
import coursesApi from "@/apiServices/coursesApi";
import imagesApi from "@/apiServices/imagesApi";
import sectionSidebar from "@/views/sections pages/components/sectionsSidebar.vue";
import sectionCard from "@/views/courses pages/components/sectionCard.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    sectionSidebar,
    sectionCard,
  },
  created() {
    this.getCategories();
  },
  data() {
    return {
      required: required,
      email: email,
      numeric: numeric,
      max_value: max_value,
      course: {
        title: "",
        description: "",
        price: null,
        duration: null,
        priceAfterDiscount: null,
        imageCover: null,
        category: "",
        sections: [],
        // subcategories: [],
        // sections: [],
      },
      categories: [],
    };
  },
  methods: {
    getCategories() {
      categoriesApi.getCategories().then((response) => {
        if (response.data) {
          this.categories = response.data;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "get categories",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    addsections(sections) {
      sections.forEach((element1) => {
        if (
          this.course.sections.findIndex(
            (element) => element._id === element1._id
          ) === -1
        ) {
          this.course.sections.push(element1);
        }
      });
    },
    createCoures(courseFormData) {
      coursesApi.createCourse(courseFormData).then((response) => {
        if (response.data) {
          this.course = response.data;
          console.log(response.data);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "course Created complite adding the sections and lessons",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Form Submitted",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },
    createCourseValidation() {
      return new Promise((resolve, reject) => {
        this.$refs.courseInfoRules.validate().then((success) => {
          if (success) {
            this.course.category = this.course?.category?._id;
            this.course.sections = this.course.sections.map((a) => a._id);
            const imageFormData = new FormData();
            imageFormData.append("image", this.course.imageCover);
            imagesApi.uploadImage(imageFormData).then((response) => {
              console.log(response);
              this.course.imageCover = response;
              this.createCoures(this.course);
              resolve(true);
            });
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>

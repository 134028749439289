var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{staticClass:"mb-3",attrs:{"color":"#00cfe8","title":null,"subtitle":null,"shape":"circle","finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":_vm.createCourseValidation}},[_c('tab-content',{attrs:{"title":"Course Details"}},[_c('validation-observer',{ref:"courseInfoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Course Details")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Enter the Course Details. ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"title","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"title","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null,"placeholder":"couse name"},model:{value:(_vm.course.title),callback:function ($$v) {_vm.$set(_vm.course, "title", $$v)},expression:"course.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"course duration in monthes  ","label-for":"duration"}},[_c('validation-provider',{attrs:{"name":"duration","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"duration","state":errors.length > 0 ? false : null,"placeholder":"course duration in monthes"},model:{value:(_vm.course.duration),callback:function ($$v) {_vm.$set(_vm.course, "duration", $$v)},expression:"course.duration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"price ","label-for":"price"}},[_c('validation-provider',{attrs:{"name":"price","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"price","state":errors.length > 0 ? false : null,"placeholder":"price"},model:{value:(_vm.course.price),callback:function ($$v) {_vm.$set(_vm.course, "price", $$v)},expression:"course.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"priceAfterDiscount","label-for":"priceAfterDiscount"}},[_c('validation-provider',{attrs:{"name":"priceAfterDiscount","rules":("numeric|max_value:" + (_vm.course.price))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"priceAfterDiscount","state":errors.length > 0 ? false : null,"placeholder":"price After Discount"},model:{value:(_vm.course.priceAfterDiscount),callback:function ($$v) {_vm.$set(_vm.course, "priceAfterDiscount", $$v)},expression:"course.priceAfterDiscount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required|min:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","placeholder":"description","state":errors.length > 0 ? false : null,"rows":"8"},model:{value:(_vm.course.description),callback:function ($$v) {_vm.$set(_vm.course, "description", $$v)},expression:"course.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Course Cover Image","label-for":"CorseCoverImage"}},[_c('validation-provider',{attrs:{"name":"CorseCoverImage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"state":Boolean(_vm.course.imageCover),"id":"CorseCoverImage","accept":"image/*"},model:{value:(_vm.course.imageCover),callback:function ($$v) {_vm.$set(_vm.course, "imageCover", $$v)},expression:"course.imageCover"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"select Course Category","label-for":"category"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","id":"category","options":_vm.categories},model:{value:(_vm.course.category),callback:function ($$v) {_vm.$set(_vm.course, "category", $$v)},expression:"course.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }